import React from 'react'

import Head from '@components/Head'
import {
  Hero,
  Logos,
  Section1,
  Section2,
  ClientSuccess,
} from '@components/pages/lp/sharepoint-intranet-consulting'
import MoreLinksSection from '@components/pages/lp/MoreLinksSection'
import { CTA } from '@components/pages/lp/sharepoint-consulting'

const SharePointConsultingLp = () => {
  return (
    <>
      <Head
        title="SharePoint Intranet Consulting Services"
        description="Over 15+ years of Microsoft SharePoint intranet development experience and expertise | Microsoft Gold Partner | Microsoft Cloud Solutions Provider (CSP)"
      />
      <Hero />
      <Logos />
      <Section1 />
      <ClientSuccess />
      <Section2 />
      <CTA />
      <MoreLinksSection />
    </>
  )
}
export default SharePointConsultingLp
