import { Container, Section } from '@components/common'
import Button from '@mrs-ui/Button'
import Tag from '@mrs-ui/Tag'
import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'

interface Feature {
  clientName: string
  clientLogo: string
  excerpt: string
  link: string
  industry: string
}

const Feature: React.FC<Feature> = ({ ...props }) => {
  return (
    <div>
      <div style={{ height: '112px' }} className="mb-6">
        <img
          src={props.clientLogo}
          className="w-1/2 object-contain max-h-full"
          alt={`Logo for ${props.clientLogo}`}
        />
      </div>
      <div className="">
        <Tag content={props.industry} className="mb-6" />
        <h3>{props.clientName}</h3>
        <p>{props.excerpt}</p>
        <p>
          <Button appearance="link" link={props.link}>
            Read the case study
          </Button>
        </p>
      </div>
    </div>
  )
}

const ClientSuccess = () => {
  const data = useStaticQuery(graphql`
    query {
      firstOntario: wpCaseStudy(id: { eq: "cG9zdDo3OA==" }) {
        id
        slug
        title
        content
        excerpt
        date(formatString: "YYYY")

        acf {
          clientName
          clientLogo {
            sourceUrl
          }
          industry
        }
      }
      kruger: wpCaseStudy(id: { eq: "cG9zdDo4OA==" }) {
        id
        slug
        title
        content
        excerpt
        date(formatString: "YYYY")

        acf {
          clientName
          clientLogo {
            sourceUrl
          }
          industry
        }
      }
      uja: wpCaseStudy(id: { eq: "cG9zdDo5Mw==" }) {
        id
        slug
        title
        content
        excerpt
        date(formatString: "YYYY")

        acf {
          clientName
          clientLogo {
            sourceUrl
          }
          industry
        }
      }
    }
  `)

  return (
    <Section className="bg-shade50">
      <Container>
        <h2 className="md:w-2/3">
          We help world-class organizations build impactful SharePoint intranets
        </h2>
        <div className="flex flex-wrap -mx-8 mt-gap-large">
          <div className="md:w-1/3 w-full px-8 pb-12">
            <Feature
              industry={data.uja.acf.industry}
              link={`/client-success/${data.uja.slug}`}
              clientLogo={data.uja.acf.clientLogo.sourceUrl}
              clientName={data.uja.acf.clientName}
              excerpt={data.uja.excerpt}
            />
          </div>
          <div className="md:w-1/3 w-full px-8 pb-12">
            <Feature
              industry={data.firstOntario.acf.industry}
              link={`/client-success/${data.firstOntario.slug}`}
              clientLogo={data.firstOntario.acf.clientLogo.sourceUrl}
              clientName={data.firstOntario.acf.clientName}
              excerpt={data.firstOntario.excerpt}
            />
          </div>
          <div className="md:w-1/3 w-full px-8 pb-12">
            <Feature
              industry={data.kruger.acf.industry}
              link={`/client-success/${data.kruger.slug}`}
              clientLogo={data.kruger.acf.clientLogo.sourceUrl}
              clientName={data.kruger.acf.clientName}
              excerpt={data.kruger.excerpt}
            />
          </div>
        </div>
        <div className="mt-6">
          <Button appearance="primary" link="/clients" actionIntent>
            Learn more about our clients
          </Button>
        </div>
      </Container>
    </Section>
  )
}
export default ClientSuccess
