import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section } from '@components/common'

import Img from 'gatsby-image'
import ContentFeature from '@components/ContentFeature'

const Section2 = () => {
  const data = useStaticQuery(graphql`
    query {
      imageCollab: file(
        relativePath: { eq: "images/stock/it-software-consulting-toronto-6.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageBPA: file(relativePath: { eq: "images/stock/img111.webp" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageECM: file(relativePath: { eq: "images/stock/h026.webp" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageAnalytics: file(
        relativePath: { eq: "images/stock/it-software-consulting-toronto-86.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section verticalSpacing="xl">
      <Container>
        <h2>What can SharePoint do for your business?</h2>
        <div className="flex flex-wrap items-center -mx-10 my-gap-xl">
          <div className="sm:w-1/2 w-full px-10 sm:mb-0 mb-gap-mini">
            <Img
              fluid={data.imageCollab.childImageSharp.fluid}
              style={{ height: '360px' }}
            />
          </div>
          <div className="sm:w-1/2 w-full px-10 mb-gap-mini">
            <h2 className="mb-4">
              Drive collaboration and inspire teamwork with a modern intranet
            </h2>
            <p>
              Deploy dynamic, interactive and mobile-responsive SharePoint sites
              for every team, department and division across your organization to
              share and organize files, data, news and other information.
              Customize your site designs to reflect your corporate brand.
              Integrate your SharePoint site content with Microsoft Teams for
              real-time chat and communication features. Deliver a pristine
              employee experience for remote workers. MRS can help you get there.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap items-center -mx-10 my-gap-xl">
          <div className="sm:w-1/2 w-full px-10 sm:mb-0 mb-gap-mini">
            <Img
              fluid={data.imageBPA.childImageSharp.fluid}
              style={{ height: '360px' }}
            />
          </div>
          <div className="sm:w-1/2 w-full px-10">
            <h2 className="mb-4">
              Transform business processes with workflow automation
            </h2>
            <p>
              Boost productivity and enforce adherence to policies and procedures
              by streamlining and automating processes - from simple tasks like
              alerts, notifications and approvals to more complex operational
              workflows - utilizing powerful tools like Microsoft Flow/Power
              Automate and K2. We can help you transform business processes and
              discover your competitive edge with SharePoint.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap items-center -mx-10 my-gap-xl">
          <div className="sm:w-1/2 w-full px-10 sm:mb-0 mb-gap-mini">
            <Img
              fluid={data.imageECM.childImageSharp.fluid}
              style={{ height: '360px' }}
            />
          </div>
          <div className="sm:w-1/2 w-full px-10">
            <h2 className="mb-4">
              Manage and organize your content efficiently and effectively
            </h2>
            <p>
              Implement well-planned and executed information architecture in
              SharePoint to give employees quick and easy access to the content
              they need. Secure and protect sensitive data and information with
              detailed permissions and approval logic. Leverage modern cloud or
              hybrid-cloud architecture to store your content and give employees
              any time, any place access. MRS SharePoint developers and
              information systems architects can help you get there.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap items-center -mx-10 my-gap-xl">
          <div className="sm:w-1/2 w-full px-10 sm:mb-0 mb-gap-mini">
            <Img
              fluid={data.imageAnalytics.childImageSharp.fluid}
              style={{ height: '360px' }}
            />
          </div>
          <div className="sm:w-1/2 w-full px-10">
            <h2 className="mb-4">
              Deliver insights with embedded analytics and business intelligence
            </h2>
            <p>
              Utilize the limitless capabilities of Microsoft Power BI and Power
              BI Embedded to integrate your SharePoint apps and data analytics
              solutions. With Power BI Embedded, we can infuse your SharePoint
              sites with highly graphical and interactive data visualisations and
              BI reports - empowering decision-makers with insights on the
              platforms and business apps they already use.
            </p>
          </div>
        </div>
      </Container>
    </Section>
  )
}
export default Section2
