import { Container, Section } from '@components/common'
import ContactForm from '@components/ContactForm'
import React from 'react'

const CTA = () => {
  return (
    <Section verticalSpacing="none">
      <Container>
        <div className="md:w-4/5 mx-auto md:border md:rounded md:px-10 py-8 mb-8">
          <div className=" w-full">
            <h2>
              Ready to discuss further? Our SharePoint experts are here to help.
            </h2>
            <p className="text-large">
              Our SharePoint team is ready to help you on the next steps of your
              SharePoint journey. Whether you're just looking for an introductory
              discussion or if you need support from SharePoint experts ASAP,
              we're here to work with you.
            </p>
            <p className="text-large">
              Fill out the contact form below and we'll get right back to you.
            </p>
          </div>
          <div className="mt-gap-xl">
            <ContactForm />
          </div>
        </div>
      </Container>
    </Section>
  )
}
export default CTA
