import React from 'react'
import { Container, Section } from '@components/common'
import ContentFeature from '@components/ContentFeature'

const MoreLinksSection = () => {
  return (
    <Section verticalSpacing="default">
      <Container>
        <h2 className="mb-gap-mini">Discover more</h2>
        <div className="flex flex-wrap -mx-8">
          <div className="md:w-1/3 w-full px-8 pb-6 mb-gap-mini">
            <ContentFeature
              title="About MRS"
              excerpt="Learn more about who we are, what we do and why we do it."
              link="/about"
              linkText="About MRS"
            />
          </div>
          <div className="md:w-1/3 w-full px-8 pb-6 mb-gap-mini">
            <ContentFeature
              title="Services & Expertise"
              excerpt="Learn more about the services and solutions we provide and discover how we can find a fit for your business."
              link="/services"
              linkText="Services"
            />
          </div>
          <div className="md:w-1/3 w-full px-8 pb-6 mb-gap-mini">
            <ContentFeature
              title="Clients"
              excerpt="There's no better way to learn about MRS than to see the companies we work with and how we help them succeed."
              link="/clients"
              linkText="Clients"
            />
          </div>
          <div className="md:w-1/3 w-full px-8 pb-6 mb-gap-mini">
            <ContentFeature
              title="Case Studies"
              excerpt="Delve into more detail on how MRS helps organizations of all sizes accelerate data-driven innovation and transform with cloud technology."
              link="/client-success"
              linkText="Case studies"
            />
          </div>
          <div className="md:w-1/3 w-full px-8 pb-6 mb-gap-mini">
            <ContentFeature
              title="Blog"
              excerpt="View the latest updates, announcements and insights from our company."
              link="/blog"
              linkText="Blog"
            />
          </div>
        </div>
      </Container>
    </Section>
  )
}
export default MoreLinksSection
