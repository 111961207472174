import React from 'react'
import { Container, Section } from '@components/common'
import ContentFeature from '@components/ContentFeature'
import Button from '@mrs-ui/Button'

const Section2 = () => {
  return (
    <Section verticalSpacing="xl">
      <Container>
        <h2 className="md:w-2/3">
          Experience the benefits of a connected, modern digital workplace powered
          by SharePoint
        </h2>
        <div className="mt-gap-large -mx-8 flex flex-wrap">
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              title="Empower remote workers"
              excerpt="An optimized SharePoint intranet - integrated with Microsoft Teams - is the foundation of your remote work experience; where remote workers go to share and access files, data and information and communicate with others."
            />
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              title="Streamline business processes"
              excerpt="Streamline and automate processes - from simple tasks like
              alerts, notifications and approvals to more complex operational
              workflows - with your SharePoint intranet utilizing powerful tools features like rich forms, Microsoft Flow/Power
              Automate and K2."
            />
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              title="Boost corporate culture"
              excerpt="Improve employee morale and boost corporate culture with a custom designed SharePoint intranet that encourages engagement, facilitates collaboration and reflects your branding."
            />
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              title="Harness organizational knowledge"
              excerpt="Implement an optimized information architecture strategy for your SharePoint intranet to give employees seamless access to the critical content and information they need to help them do their jobs effectively."
            />
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              title="Improve onboarding processes"
              excerpt="Utilize SharePoint's New Employee Onboarding (NEO) Hub or build completely custom SharePoint sites and pages to provide new employees with resources and support and help connect them to the people, community and culture integral to your organization."
            />
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              title="Leverage embedded analytics"
              excerpt="Integrate your various data sources and give decision-makers access to highly graphical and interactive business intelligence reports on your SharePoint intranet pages with Microsoft Power BI Embedded."
            />
          </div>
        </div>
        <div className="mt-6">
          <Button appearance="primary" link="/contact" actionIntent>
            Speak to a SharePoint expert today
          </Button>
        </div>
      </Container>
    </Section>
  )
}
export default Section2
