import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section } from '@components/common'
import { Button } from '@mrs-ui'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: {
          eq: "images/stock/it-software-consulting-toronto-61.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section
      verticalSpacing="xxl"
      backgroundImage={data.image.childImageSharp.fluid}
      theme="dark"
      overlay
    >
      <Container>
        <div className="md:w-1/2 w-full">
          <h1 className="mb-8">SharePoint Consulting & Development Services</h1>
          <p className="text-large">
            Make the most of SharePoint with Toronto's most trusted and
            experienced SharePoint services provider. 15+ years of SharePoint
            experience. Certified Microsoft Gold Partner and Cloud Solutions
            Provider (CSP).
          </p>
          <p className="text-large">
            Are you ready to unleash your modern workplace?
          </p>
        </div>
        <div className="flex flex-wrap mt-gap-default items-center">
          <Button className="mr-gap-mini" appearance="primary" link="/contact">
            Contact us for SharePoint services today
          </Button>
        </div>
      </Container>
    </Section>
  )
}
export default Hero
