import { Container } from '@components/common'
import { LogoRow } from '@components/common'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

const Logos = () => {
  const data = useStaticQuery(graphql`
    query {
      goldPartnerLogo: file(
        relativePath: { eq: "images/logos/partners/logo-msgp.png" }
      ) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      cspLogo: file(relativePath: { eq: "images/logos/partners/ms-csp.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sharePointLogo: file(
        relativePath: { eq: "images/logos/tech/logo_sharepoint.png" }
      ) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const logos = [
    {
      image: data.goldPartnerLogo.childImageSharp.fixed,
      alt: 'Microsoft Gold Partner logo',
    },
    {
      image: data.cspLogo.childImageSharp.fixed,
      alt: 'Microsoft Cloud Solutions Provider logo',
    },
    {
      image: data.sharePointLogo.childImageSharp.fixed,
      alt: 'Microsoft SharePoint logo/icon',
    },
  ]
  return (
    <section className="py-gap-default overflow-hidden">
      <LogoRow logos={logos} />
    </section>
  )
}
export default Logos
