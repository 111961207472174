import React from 'react'
import { Container, Section } from '@components/common'
import ContentFeature from '@components/ContentFeature'
import Icon from '@mrs-ui/Icon'
import { graphql, useStaticQuery } from 'gatsby'
import Button from '@mrs-ui/Button'

const Section1 = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "images/stock/h026.webp" }) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image2: file(relativePath: { eq: "images/stock/h022.webp" }) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image3: file(
        relativePath: {
          eq: "images/stock/it-software-consulting-toronto-61.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Section verticalSpacing="xl">
      <Container>
        <h2>Your trusted SharePoint intranet services provider</h2>
        <div className="mt-gap-large -mx-8 flex flex-wrap">
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              image={data.image1.childImageSharp.fluid}
              title="15+ years of SharePoint experience"
              excerpt="MRS has been developing SharePoint intranets since its inception over 15 years ago. With that comes a breadth of experience unparalleled in the industry and deep expertise in all Microsoft 365 services - from SharePoint and OneDrive to Microsoft Teams, Power Platform and Power BI."
            />
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              image={data.image2.childImageSharp.fluid}
              title="Modern SharePoint development"
              excerpt="Our SharePoint developers and consultants are always up-to-date on the newest features and rigorously adhere to Microsoft's recommended best practices for SharePoint development and architecture, such as leveraging the modern SharePoint Framework (SPFx) with Javascript and Typescript, React.JS, Angular and Vue.js."
            />
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              image={data.image3.childImageSharp.fluid}
              title="Microsoft Partner expertise and exclusive access"
              excerpt="Through the Microsoft Partner network, our SharePoint consultants earn certifications to demonstrate and reinforce their SharePoint expertise and our team gains exclusive access to SharePoint resources from Microsoft, such as technical enablement training, support, licenses and more."
            />
          </div>
        </div>

        <div className="mt-6">
          <Button
            appearance="primary"
            link="/lp/sharepoint-consulting"
            actionIntent
          >
            Learn more about our SharePoint services
          </Button>
        </div>
      </Container>
    </Section>
  )
}
export default Section1
