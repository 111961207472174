import React from 'react'
import { Container, Section } from '@components/common'
import ContentFeature from '@components/ContentFeature'
import Icon from '@mrs-ui/Icon'
import { graphql, useStaticQuery } from 'gatsby'
import Button from '@mrs-ui/Button'

const Section1 = () => {
  const data = useStaticQuery(graphql`
    query {
      imageConsulting: file(
        relativePath: { eq: "images/stock/it-software-consulting-toronto-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageDev: file(relativePath: { eq: "images/stock/h004.webp" }) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageManagedServices: file(
        relativePath: { eq: "images/stock/it-software-consulting-toronto-94.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Section verticalSpacing="xl">
      <Container>
        <div className="md:w-4/5 w-full">
          <h2>All of your SharePoint needs, all in one place</h2>
          <p>
            MRS offers an unparalleled combination of business and technical
            expertise to deliver a full suite of SharePoint services for
            medium-sized businesses to enterprise. We bring 15+ years of
            SharePoint experience and a team of certified Microsoft technology
            professionals to each project to help our clients realize results with
            SharePoint.
          </p>
        </div>

        <div className="mt-gap-large -mx-8 flex flex-wrap">
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              image={data.imageConsulting.childImageSharp.fluid}
              title="SharePoint Consulting"
              excerpt="Discover the benefits of Microsoft SharePoint for your business and identify untapped potential in your SharePoint deployment. Our goal is to help you make the most of SharePoint and to maximize your investments by helping you utilize the latest and most powerful features and functionalities."
            />
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              image={data.imageDev.childImageSharp.fluid}
              title="SharePoint Development and Implementation"
              excerpt="Bring your SharePoint visions to life with help from trusted experts at MRS. We provide end-to-end SharePoint deployments, upgrades and migrations, custom SharePoint development, application integration services and more."
            />
          </div>
          <div className="lg:w-1/3 md:w-1/2 w-full px-8 pb-6">
            <ContentFeature
              image={data.imageManagedServices.childImageSharp.fluid}
              title="SharePoint Managed Services and Support"
              excerpt="Leverage SharePoint to the maximum potential without the challenges associated with onboarding technical staff and provisioning resources. Our SharePoint team provides onboarding, training, 24/7 helpdesk support and provisioning services to help your business ensure mission-critical availability and security and to help you identify untapped potential with value-added services."
            />
          </div>
        </div>
        <div className="mt-6">
          <Button appearance="primary" link="/contact" actionIntent>
            Speak to a SharePoint expert today
          </Button>
        </div>
      </Container>
    </Section>
  )
}
export default Section1
