import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Section } from '@components/common'
import { Button } from '@mrs-ui'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: { eq: "images/stock/it-software-consulting-toronto-62.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section
      verticalSpacing="xxl"
      backgroundImage={data.image.childImageSharp.fluid}
      theme="dark"
    >
      <Container>
        <div className="md:w-5/12 w-full">
          <h1 className="mb-8">SharePoint Intranet Services</h1>
          <p className="text-large">
            Deploy dynamic, engaging SharePoint intranets that delight employees,
            empower remote workers and deliver business results faster. We offer
            end-to-end SharePoint intranet consulting, development and support
            services for SMB to enterprise.
          </p>
        </div>
        <div className="flex flex-wrap mt-gap-default items-center">
          <Button className="mr-gap-mini" appearance="primary" link="/contact">
            Let's get to work
          </Button>
        </div>
      </Container>
    </Section>
  )
}
export default Hero
